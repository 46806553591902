<template>
	<div class="garden-list-page">
		<div class="search-box">
			<a-row class="search-row">
				<a-col :span="7">
					<a-row class="">
						<a-col :span="6" class="label-box">类型：</a-col>
						<a-col :span="18" class="value-box">
							<a-select v-model="park_type_id" class="a-select" placeholder="请选择" @change="searchList">
								<a-select-option :value="item.service_type_id" v-for="(item,index) in ParkTypeList" :key="item.service_type_id">
									{{item.service_type_name}}
								</a-select-option>
							  </a-select>
						</a-col>
					</a-row>
				</a-col>
				<a-col :span="7">
					<a-row class="">
						<a-col :span="6" class="label-box">名称：</a-col>
						<a-col :span="18" class="value-box">
							<a-input placeholder="请输入" v-model="park_name"/>
						</a-col>
					</a-row>
				</a-col>
				<a-col :span="7">
					<a-row class="">
						<a-col :span="6" class="label-box">状态：</a-col>
						<a-col :span="18" class="value-box">
							<a-select v-model="status" class="a-select" placeholder="请选择">
								<a-select-option :value="1">
								 启用
								</a-select-option>
								<a-select-option :value="-1">
								  关闭
								</a-select-option>
							  </a-select>
						</a-col>
					</a-row>
				</a-col>
				<a-col class="btn-box">
					<a-button type="default" @click="resetTiao">重置</a-button>
					<a-button type="primary" @click="searchList">查询</a-button>
				</a-col>
			</a-row>
			
		</div>
		<div class="color-block"></div>
		<div class="list-table-box">
			<div class="control-box">
				<a-button type="primary" @click="toDetail(0)">
					<a-icon type="plus-circle" />
					添加
				</a-button>
			</div>
			<a-table :rowKey="(item,index) => {return index}" 
				:loading="dataLoading" 
				:columns="columns"
				:data-source="list"
				:pagination="{
					showTotal: total => `共 ${page.total} 条政策记录，总浏览量${sum_view}`,
					current: page.current, total: page.total}"
				@change="listChange"
				:bordered="true">
				<template slot="header_img" slot-scope="index, item">
					<div class="head-img-box">
						<img :src="$imglink+item.header_img" >
					</div>
				</template>
				<template slot="is_show" slot-scope="index, item">
					<div class="qiy" :class="{act : item.is_show == 1}" @click="toggleShow(item.article_id,item.is_show)">
						{{item.is_show == 1 ? '启用' : '关闭'}}
					</div>
				</template>
				<template slot="operation" slot-scope="index, item">
					<div class="control-box">
						<a-button size="small" type="primary" @click="toEdit(item)">编辑</a-button>
						<a-popconfirm title="确认删除该政策" ok-text="是" cancel-text="否" @confirm="deleteItem(item)">
							<a-button size="small" type="danger">删除</a-button>
						</a-popconfirm>
					</div>
				</template>
			</a-table>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			let columns = [{
				title: '类型',
				key: 'service_type_name',
				dataIndex: 'service_type_name'
			},{
				title: '展示图',
				dataIndex: 'header_img',
				width: '148px',
				scopedSlots: { customRender: 'header_img' }
			},{
				title: '名称',
				key: 'article_name',
				dataIndex: 'article_name'
			},{
				title: '来源',
				key: 'source',
				dataIndex: 'source'
			},
			{
				title: '状态',
				dataIndex: 'is_show',
				scopedSlots: { customRender: 'is_show' },
				width: '65px'
			},
			{
				title: '发布时间',
				key: 'create_at_str',
				dataIndex: 'create_at_str',
				width: '105px'
			},{
				title: '操作',
				dataIndex: 'operation',
				scopedSlots: { customRender: 'operation' },
				width: '140px'
			}]
			return {
				dataLoading: false,
				columns,
				list: [],
				page:{
					current: 1,
					total: 0
				},
				park_type_id: undefined,
				park_name: '',
				status: undefined,
				ParkTypeList:[],
				showIng: false,
				reIng: false,
				sum_view: 0
			}
		},
		computed: {},
		created() {
			this.getParkTypeList()
			this.getParkList()
		},
		mounted() {
			
		},
		methods:{
			searchList() {
				let t = this;
				t.page.current = 1
				t.getParkList()
			},
			getParkTypeList() {
				let t = this
				t.$post('serviceTypeSelect',{limit: 9999}).then(res => {
					let { code, data, msg } = res
					if (code == 0) {
						t.ParkTypeList = data
					} else {
						t.$message.error(msg, 1.5)
					}
				}).catch(err => {
				
				})
			},
			getParkList() {
				let t = this
				let data ={
					service_type_id: t.park_type_id || '',
					article_name: t.park_name,
					is_show: t.status || '',
					page: t.page.current
				}
				console.log(data)
				t.$post('ServiceArticleList',data).then(res => {
					let { code, data, msg } = res
					if (code == 0) {
						t.list = data.list
						t.page.total = data.total
						t.sum_view = data.sum_view
					} else {
						t.$message.error(msg, 1.5)
					}
				}).catch(err => {
				
				})
			},
			toDetail(type) {
				this.$router.push({path: '/serveDetail'})
			},
			toEdit(item) {
				this.$router.push({path: '/serveDetail', query:{id: item.article_id}})
			},
			toggleShow(id,is_show) {
				let t = this
				if(t.showIng) {
					return 
				}else{
					t.showIng = true
				}
				t.$post('ServiceArticleFabu',{article_id: id}).then(res => {
					t.showIng = false
					let { code, data, msg } = res
					if (code == 0) {
						let str = is_show == 1 ? '已关闭' : '已启用'
						t.$message.success(str,1.5)
					    t.getParkList()
					} else {
						t.$message.error(msg, 1.5)
					}
				}).catch(err => {
					t.showIng = false
				})
			},
			deleteItem(item) {
				let t = this
				t.$post('ServiceArticleDelete',{article_id: item.article_id}).then(res => {
					let { code, data, msg } = res
					if (code == 0) {
						t.$message.success('删除成功',1.5)
					    t.searchList()
					} else {
						t.$message.error(msg, 1.5)
					}
				}).catch(err => {
				
				})
			},
			listChange(e) {
				this.page.current = e.current
				this.getParkList();
			},
			resetTiao() {
				this.park_type_id = undefined
				this.park_name = ''
				this.status = undefined
				// this.searchList()
			},
 		}
	}
</script>

<style lang="less">
	.head-img-box {
		width: 100%;
		max-height: 120px;
		img{
			width: 100%;
			height: 100%;
			display: block;
			object-fit: contain;
		}
	}
	
	
</style>
